define("ember-cli-mirage/utils/ember-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasEmberData = void 0;
  _exports.isDsModel = isDsModel;
  /* global requirejs */

  function _hasEmberData() {
    var matchRegex = /^ember-data/i;
    return !!Object.keys(requirejs.entries).find(function (e) {
      return !!e.match(matchRegex);
    });
  }

  /**
    @hide
  */
  var hasEmberData = _exports.hasEmberData = _hasEmberData();

  /**
    @hide
  */
  function isDsModel(m) {
    return m && typeof m.eachRelationship === 'function';
  }
});