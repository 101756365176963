define("ember-can/ability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    model: null,
    /**
     * Parse propertyName into ability property
     * eg: `createProject` will be parsed to `canCreateProject` using default definition
     * @public
     * @param  {[String]} propertyName [description]
     * @return {[String]}              [description]
     */
    parseProperty: function parseProperty(propertyName) {
      return Ember.String.camelize("can-".concat(propertyName));
    },
    /**
     * Get parsed ability value based on propertyName
     * eg: `createProject` will return a value for `canCreateProject`
     * using default `parseProperty` definition
     * @private
     * @param  {String} propertyName property name, eg. `createProject`
     * @return {*}                   value of parsed `propertyName` property
     */
    getAbility: function getAbility(propertyName) {
      return this.get(this.parseProperty(propertyName));
    }
  });
});