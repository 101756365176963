define("ember-bootstrap/version", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VERSION = void 0;
  _exports.registerLibrary = registerLibrary;
  var VERSION = _exports.VERSION = (0, _runtime.config)("/home/runner/work/miejscowka/miejscowka/frontend/miejscowka/node_modules/ember-bootstrap").version;
  function registerLibrary() {
    Ember.libraries.register('Ember Bootstrap', VERSION);
  }
});