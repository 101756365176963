define("ember-currencies-and-format-money/format-amount", ["exports", "ember-currencies-and-format-money/currencies"], function (_exports, _currencies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatMoneyAmount = formatMoneyAmount;
  _exports.formatMoneyAmountByCurrency = formatMoneyAmountByCurrency;
  function formatMoneyAmount(amount, digitSeparator, decimalSeparator, currencyPlacement, symbol, smallestUnitRatio, currencyHidden) {
    var amountFinal = amount / smallestUnitRatio;
    var powerOfTen = Math.log(smallestUnitRatio) * Math.LOG10E;
    amountFinal = amountFinal.toLocaleString("en-US", {
      minimumFractionDigits: powerOfTen
    });
    amountFinal = amountFinal.replace(/\./g, "_");
    amountFinal = amountFinal.replace(/,/g, digitSeparator);
    amountFinal = amountFinal.replace(/_/g, decimalSeparator);
    var result = "";
    if (currencyHidden) {
      result = amountFinal;
    } else if (currencyPlacement === 's') {
      result = amountFinal + symbol;
    } else {
      result = symbol + amountFinal;
    }
    return result;
  }
  function formatMoneyAmountByCurrency(amount, currencyValue, currencyHidden) {
    var currency = _currencies.currenciesObject[currencyValue];
    if (Ember.isBlank(currency)) {
      (true && !(false) && Ember.assert("undefined currency : " + currencyValue));
    }
    var currencyPlacement = currency.currencyPlacement;
    var digitSeparator = currency.digitSeparator;
    var decimalSeparator = currency.decimalSeparator;
    var symbol = currency.symbol;
    var smallestUnitRatio = currency.smallestUnitRatio;
    return formatMoneyAmount(amount, digitSeparator, decimalSeparator, currencyPlacement, symbol, smallestUnitRatio, currencyHidden);
  }
});