define("ember-can/utils/normalize", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var stopWords = ['of', 'in', 'for', 'to', 'from', 'on', 'as'];

  /**
   * Normalize string into an object with extracted propertyName and abilityName
   * eg. for 'create projects in account' -> `{ propertyName: 'createProjects', abilityName: 'account'}`
   * @private
   * @param  {String} string eg. 'create projects in account'
   * @return {Object}        extracted propertyName and abilityName
   */
  function _default(string) {
    var parts = string.split(' ');
    var abilityName = (0, _emberInflector.singularize)(parts.pop());
    var last = parts[parts.length - 1];
    if (stopWords.includes(last)) {
      parts.pop();
    }
    var propertyName = Ember.String.camelize(parts.join(' '));
    return {
      propertyName: propertyName,
      abilityName: abilityName
    };
  }
});