define("ember-can/helpers/can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Helper.extend({
    can: Ember.inject.service(),
    ability: null,
    propertyName: null,
    compute: function compute(_ref, properties) {
      var _ref2 = _slicedToArray(_ref, 2),
        abilityString = _ref2[0],
        model = _ref2[1];
      var _this$can$parse = this.can.parse(abilityString),
        abilityName = _this$can$parse.abilityName,
        propertyName = _this$can$parse.propertyName;
      var ability = this.can.abilityFor(abilityName, model, properties);
      propertyName = ability.parseProperty(propertyName);
      this._removeAbilityObserver();
      this._addAbilityObserver(ability, propertyName);
      return Ember.get(ability, propertyName);
    },
    destroy: function destroy() {
      this._removeAbilityObserver();
      return this._super.apply(this, arguments);
    },
    _addAbilityObserver: function _addAbilityObserver(ability, propertyName) {
      Ember.setProperties(this, {
        ability: ability,
        propertyName: propertyName
      });
      Ember.addObserver(this, "ability.".concat(propertyName), this, 'recompute');
    },
    _removeAbilityObserver: function _removeAbilityObserver() {
      Ember.removeObserver(this, "ability.".concat(this.propertyName), this, 'recompute');
      this.ability && this.ability.destroy();
      Ember.setProperties(this, {
        ability: null,
        propertyName: null
      });
    }
  });
});