define("ember-can/helpers/cannot", ["exports", "ember-can/helpers/can"], function (_exports, _can) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _can.default.extend({
    compute: function compute() {
      return !this._super.apply(this, arguments);
    }
  });
});