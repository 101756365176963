define("ember-l10n/utils/guess-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getLocalAlias = getLocalAlias;
  _exports.guessLocale = guessLocale;
  _exports.matchLocale = matchLocale;
  _exports.normalizeLocale = normalizeLocale;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   * Guess a locale based on allowed & desired locales.
   * This will return the best-fitting locale.
   *
   * Given the following input:
   * allowedLocales = ['en', 'de', 'zh_HK']
   * desiredLocales = ['de-AT', 'de', 'en-US', 'en']
   *
   * It would by default return 'de'.
   *
   * If you specify `allowSubLocales=true`, it would instead return `de_AT`, the favorite sub-locale.
   *
   * In contrast, the following input:
   * allowedLocales = ['en', 'de', 'zh_HK']
   * desiredLocales = ['zh-CN', 'zh-HK', 'en-US', 'en']
   *
   * Would always return 'zh_HK', no matter if sub locales are allowed or not.
   *
   * @method guessLocale
   * @param allowedLocales
   * @param desiredLocales
   * @param defaultLocale
   * @param allowSubLocales
   * @return {String}
   */
  function guessLocale() {
    var allowedLocales = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var desiredLocales = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$defaultLocale = _ref.defaultLocale,
      defaultLocale = _ref$defaultLocale === void 0 ? 'en' : _ref$defaultLocale,
      _ref$allowSubLocales = _ref.allowSubLocales,
      allowSubLocales = _ref$allowSubLocales === void 0 ? false : _ref$allowSubLocales;
    desiredLocales = desiredLocales || [defaultLocale];
    desiredLocales = desiredLocales.map(normalizeLocale).map(getLocalAlias);

    // Ensure everything is an Ember Array
    if (!desiredLocales.find) {
      desiredLocales = Ember.A(desiredLocales);
    }
    if (!allowedLocales.find) {
      allowedLocales = Ember.A(allowedLocales);
    }
    var locale = desiredLocales.find(function (locale) {
      return allowedLocales.find(function (allowedLocale) {
        return matchLocale(locale, allowedLocale);
      });
    }) || defaultLocale;

    // If allowSubLocales=false, we do not want to return sub locales
    // For example, if 'de' is allowed, but the first matching locale is de_AT, it will return 'de' if true, else de_AT.
    if (allowSubLocales || allowedLocales.indexOf(locale) !== -1) {
      return locale;
    }
    return allowedLocales.find(function (allowedLocale) {
      return locale.indexOf(allowedLocale) === 0;
    }) || defaultLocale;
  }
  function normalizeLocale(locale) {
    locale = locale.replace('-', '_');
    var _locale$split = locale.split('_'),
      _locale$split2 = _slicedToArray(_locale$split, 2),
      mainLocale = _locale$split2[0],
      region = _locale$split2[1];
    if (region) {
      return "".concat(mainLocale, "_").concat(region.toUpperCase());
    }
    return mainLocale;
  }
  function getLocalAlias(locale) {
    // There are variations of chinese locales
    // We need to map those to either Simplified (CN) or Traditional (HK).
    // Sadly, we cannot simply fall back to zh here, as that is not actually a valid locale
    switch (locale) {
      case 'zh_CN':
      case 'zh_SG':
      case 'zh_Hans':
      case 'zh':
        return 'zh_CN';
      case 'zh_HK':
      case 'zh_TW':
      case 'zh_MO':
      case 'zh_Hant':
        return 'zh_HK';
    }
    return locale;
  }
  function matchLocale(localeA, localeB) {
    if (localeA === localeB) {
      return true;
    }
    return localeA.indexOf(localeB) === 0;
  }
  var _default = _exports.default = guessLocale;
});