define("ember-bootstrap-power-select/components/bs-form/element/control/power-select-multiple", ["exports", "ember-bootstrap/components/bs-form/element/control", "ember-bootstrap-power-select/templates/components/bs-form/element/control/power-select-multiple"], function (_exports, _control, _powerSelectMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _control.default.extend({
    layout: _powerSelectMultiple.default,
    tagName: '',
    /**
     * @property powerSelectComponent
     * @type {String}
     */
    powerSelectComponent: 'power-select-multiple'
  });
});