define("ember-cli-maskedinput/initializers/paste-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    var customEvents = application.get('customEvents') || {};
    customEvents.paste = 'paste';
    application.set('customEvents', customEvents);
  }
  var _default = _exports.default = {
    name: 'paste-event',
    initialize: initialize
  };
});