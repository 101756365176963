define("ember-can/services/can", ["exports", "ember-can/ability", "ember-can/utils/normalize"], function (_exports, _ability, _normalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    /**
     * Parse ablityString into an object with extracted propertyName and abilityName
     * eg. for 'create projects in account' -> `{ propertyName: 'createProjects', abilityName: 'account'}`
     * @public
     * @param  {String} string eg. 'create projects in account'
     * @return {Object}        extracted propertyName and abilityName
     */
    parse: function parse(abilityString) {
      return (0, _normalize.default)(abilityString);
    },
    /**
     * Create an instance of Ability
     * @public
     * @param  {String} abilityName     name of ability class
     * @param  {*}      model
     * @param  {Object} [properties={}] extra properties (to be set on the ability instance)
     * @return {Ability}                Ability instance of requested ability
     */
    abilityFor: function abilityFor(abilityName, model) {
      var properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var AbilityFactory = Ember.getOwner(this).factoryFor("ability:".concat(abilityName));
      (true && !(AbilityFactory) && Ember.assert("No ability type found for '".concat(abilityName, "'"), AbilityFactory));
      if (typeof model !== 'undefined') {
        properties = Ember.assign({}, {
          model: model
        }, properties);
      }
      var ability = AbilityFactory.create(properties);
      (true && !(ability instanceof _ability.default) && Ember.assert("Ability '".concat(abilityName, "' has to inherit from ember-can Ability"), ability instanceof _ability.default));
      return ability;
    },
    /**
     * Returns a value for requested ability in specified ability class
     * @public
     * @param  {String} propertyName name of ability, eg `createProjects`
     * @param  {String} abilityName  name of ability class
     * @param  {*}      model
     * @param  {Object} properties   extra properties (to be set on the ability instance)
     * @return {*}                   value of ability
     */
    valueFor: function valueFor(propertyName, abilityName, model, properties) {
      var ability = this.abilityFor(abilityName, model, properties);
      var result = ability.getAbility(propertyName);
      ability.destroy();
      return result;
    },
    /**
     * Returns `true` if ability is permitted
     * @public
     * @param  {[type]} abilityString eg. 'create projects in account'
     * @param  {*}      model
     * @param  {[type]} properties    extra properties (to be set on the ability instance)
     * @return {Boolean}              value of ability converted to boolean
     */
    can: function can(abilityString, model, properties) {
      var _this$parse = this.parse(abilityString),
        propertyName = _this$parse.propertyName,
        abilityName = _this$parse.abilityName;
      return !!this.valueFor(propertyName, abilityName, model, properties);
    },
    /**
     * Returns `true` if ability is not permitted
     * @public
     * @param  {[type]} abilityString eg. 'create projects in account'
     * @param  {*}      model
     * @param  {[type]} properties    extra properties (to be set on the ability instance)
     * @return {Boolean}              value of ability converted to boolean
     */
    cannot: function cannot(abilityString, model, properties) {
      return !this.can(abilityString, model, properties);
    }
  });
});